import React from 'react'
import { graphql, PageProps } from 'gatsby'
import styled from 'styled-components'
import media from 'styles/media'

import { Container } from 'components/atoms/Container'
import { Layout } from 'views/Layout'
import { Seo } from 'components/atoms/Seo'
import { LeftContentHero } from 'components/modules/LeftContentHero'
import { Breadcrumbs } from 'components/modules/Breadcrumbs'
import { LocationAdvantages } from 'components/organisms/LocationAdvantages'
import { Directions } from 'components/organisms/Directions'
import { NearbyPlaces } from 'components/organisms/NearbyPlaces'
import { Contact } from 'components/organisms/Contact'
import MapContainer from 'components/atoms/MapContainer'

const BreadcrumbsContainer = styled(Container)`
  max-width: 1216px;

  ${media.sm.max} {
    padding: 0px;
  }
`
const LokalizacjaPage: React.FC<PageProps<Queries.LokalizacjaPageQuery>> = ({
  data,
}) => {
  const PAGE = data.wpPage?.location
  const PAGE_SEO = data.wpPage?.seo!
  const POPUP = data.wpPage?.TypePage ?? null

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const LOCATION_ADVANTAGES =
    PAGE.locationAdvantagesTiles?.map((tile) => ({
      positionRight: tile?.locationAdvantagesTilesSide === 'right',
      image: {
        src: tile?.locationAdvantagesTilesImg?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: tile?.locationAdvantagesTilesImg?.altText!,
      },
      label: tile?.locationAdvantagesTilesTitle!,
      text: tile?.locationAdvantagesTilesDescription!,
    })) || []

  return (
    <Layout
      popup={
        POPUP
          ? {
              image: {
                src: POPUP?.popupImage?.localFile?.childImageSharp
                  ?.gatsbyImageData!,
                alt: POPUP?.popupImage?.altText!,
              },
              active: POPUP?.popupActive!,
              activeMobile: POPUP?.popupActiveMobile!,
              delay: POPUP?.popupDelay!,
              link: POPUP?.popupLink!,
            }
          : undefined
      }
    >
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <MapContainer size="big" position={['bottom', 'right']}>
        <LeftContentHero
          image={{
            src: PAGE.locationHeroImg?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: PAGE.locationHeroImg?.altText!,
          }}
          lable={PAGE.locationHeroTitle!}
          text={PAGE.locationHeroCommunication!}
          textTag="h4"
          headingTag="h1"
        />
      </MapContainer>
      <BreadcrumbsContainer margin={[0, 'auto']} padding={[0, 40]}>
        <Breadcrumbs crumbs={[{ name: data.wpPage.title! }]} />
      </BreadcrumbsContainer>
      <LocationAdvantages
        heading={PAGE.locationAdvantagesTitle!}
        text={PAGE.locationAdvantagesDescription!}
        tiles={LOCATION_ADVANTAGES}
      />
      <Directions
        heading={PAGE.locationMapHeading!}
        locationCoords={{
          lat: Number(
            PAGE.locationMapLocationCoordinates
              ?.locationMapLocationCoordinatesLat!
          ),
          lng: Number(
            PAGE.locationMapLocationCoordinates
              ?.locationMapLocationCoordinatesLng!
          ),
        }}
      />
      <MapContainer size="big" position={['bottom', 'left']}>
        <NearbyPlaces
          heading={PAGE.locationKeyPlacesHeading!}
          walkingPlacesHeading={
            PAGE.locationKeyPlacesWalking?.locationKeyPlacesWalkingHeading!
          }
          walkingPlaces={
            PAGE.locationKeyPlacesWalking?.locationKeyPlacesWalkingPlaces?.map(
              (place) => place?.locationKeyPlacesWalkingPlacesItem!
            ) || []
          }
          drivingPlacesHeading={
            PAGE.locationKeyPlacesDriving?.locationKeyPlacesDrivingHeading!!
          }
          drivingPlaces={
            PAGE.locationKeyPlacesDriving?.locationKeyPlacesDrivingPlaces?.map(
              (place) => place?.locationKeyPlacesDrivingPlacesItem!
            ) || []
          }
          image={{
            src: PAGE.locationKeyPlacesImg?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: PAGE.locationKeyPlacesImg?.altText!,
          }}
        />
      </MapContainer>
      <Contact
        heading={PAGE.locationFormTitle!}
        formTitle={PAGE.locationFormFormTitle!}
        image={{
          src: PAGE.locationFormImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE.locationFormImg?.altText!,
        }}
      />
    </Layout>
  )
}

export default LokalizacjaPage

export const query = graphql`
  query LokalizacjaPage {
    wpPage(slug: { regex: "/lokalizacja/" }) {
      TypePage {
        popupActive
        popupActiveMobile
        popupDelay
        popupLink
        popupImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
      }
      title
      location {
        locationHeroTitle
        locationHeroCommunication
        locationKeyPlacesHeading
        locationMapHeading
        locationFormTitle
        locationFormFormTitle
        locationAdvantagesTitle
        locationAdvantagesDescription
        locationAdvantagesTiles {
          locationAdvantagesTilesDescription
          locationAdvantagesTilesSide
          locationAdvantagesTilesTitle
          locationAdvantagesTilesImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
          }
        }
        locationFormImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
        locationHeroImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        locationKeyPlacesDriving {
          locationKeyPlacesDrivingHeading
          locationKeyPlacesDrivingPlaces {
            locationKeyPlacesDrivingPlacesItem
          }
        }
        locationKeyPlacesImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
        }
        locationKeyPlacesWalking {
          locationKeyPlacesWalkingHeading
          locationKeyPlacesWalkingPlaces {
            locationKeyPlacesWalkingPlacesItem
          }
        }
        locationMapLocationCoordinates {
          locationMapLocationCoordinatesLat
          locationMapLocationCoordinatesLng
        }
      }
      seo {
        ...WpSEO
      }
    }
  }
`
